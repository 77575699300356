/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
// import PropTypes from 'prop-types';
import { Twitter, Facebook, Instagram, YouTube } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SearchIcon from '@mui/icons-material/Search';

import {
  Grid,
  IconButton,
  InputAdornment,
  Container,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';
import {
  FooterContainer,
  FooterColumn,
  SocialList,
  Logo,
  ColumnHeader,
  ColumnContent,
  useClasses,
} from './style';
import Link from '../../../atoms/Link';
import SubFooter from '../SubFooter';
import { useTranslation } from '../../../../config/i18n';
import TextField from '../../../atoms/TextField';
import Button from '../../../atoms/Button';
import { colors } from '../../../../config/colors';

const FooterLogo = '/images/LogoAfterLogin.png';
const belsop = '/images/belspo.png';
const creativeEropean = '/images/FITD.jpg';
const JMILogo = '/images/techvenia-logo.png';

const Footer = () => {
  const { t } = useTranslation(['footer', 'warnings', 'explore', 'dashboard']);

  const classes = useClasses();

  return (
    <>
      <FooterContainer>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item xs={12} sm={6} md={4}>
              <FooterColumn>
                <ColumnHeader>
                  <Logo bottom="0.3125rem" width="12.5rem" src={FooterLogo} />
                </ColumnHeader>
                <Typography variant="body2" style={{ color: 'black' }}>
                  {t('about_details')}
                </Typography>
                <TextField
                  defaultValue=""
                  value=""
                  // value="aaa"
                  name="FooterSearch"
                  placeholder={t('explore:search')}
                  // onChange={handleChange}
                  className={classes.Search}
                  variant="outlined"
                  // onKeyDown={handleKeyDownTitle}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon style={{ fill: colors.primary.main }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                // InputLabelProps={{ shrink: Boolean(values.title) }}
                />
                <SocialList>
                  <li>
                    <a
                      title="facebook"
                      rel="noopener"
                      target="_blank"
                      href="https://www.facebook.com/techvenia"
                    >
                      <Facebook style={{ fill: colors.primary.main }} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="instagram"
                      rel="noopener"
                      target="_blank"
                      href="https://www.instagram.com/techvenia/"
                    >
                      <Instagram style={{ fill: colors.primary.main }} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="instagram"
                      rel="noopener"
                      target="_blank"
                      href="https://www.linkedin.com/company/18842510/admin/"
                    >
                      <LinkedInIcon style={{ fill: colors.primary.main }} />
                    </a>
                  </li>
                  {/* <li>
                    <a
                      title="twitter"
                      rel="noopener"
                      target="_blank"
                      href="https://www.twitter.com/mubazar"
                    >
                      <Twitter style={{ fill: colors.primary.main }} />
                    </a>
                  </li>
                  <li>
                    <a
                      title="youtube"
                      rel="noopener"
                      target="_blank"
                      href="https://www.youtube.com/mubazar"
                    >
                      <YouTube style={{ fill: colors.primary.main }} />
                    </a>
                  </li> */}
                </SocialList>
              </FooterColumn>
            </Grid>
            <Grid item lxs={12} sm={6} md={4} className={classes.Quicklinks}>
              <FooterColumn>
                <ColumnHeader>
                  <Typography variant="subtitle1" color="black">
                    {t('quick links')}
                  </Typography>
                </ColumnHeader>
                <ColumnContent>
                  <ul>
                    <li>
                      <Link href="about_us">{t('about')}</Link>
                    </li>
                    <li>
                      <Link href="team_members">{t('team_members')}</Link>
                    </li>
                    <li>
                      <Link href="help">{t('help')}</Link>
                    </li>
                    <li>
                      <Link href="terms">{t('terms')}</Link>
                    </li>
                    <li>
                      <Link href="privacy">{t('privacy')}</Link>
                    </li>
                    <li>
                      <Link href="contact">{t('contact')}</Link>
                    </li>
                    {/* <li>
                    <a href="https://mubazar.com/">{t('warnings:legacy')}</a>
                  </li> */}
                  </ul>
                </ColumnContent>
              </FooterColumn>
            </Grid>
            <Grid item xs={12} sm={9} md={4}>
              <Box display="flex" flexDirection="column">
                <Box className={classes.BoxWithJMLogo}>
                  <FooterColumn>
                    <ColumnHeader>
                      <Typography variant="subtitle1" color="black">
                        {t('powered')}
                      </Typography>
                    </ColumnHeader>
                    <ColumnContent>
                      <a href="https://techvenia.com" target="_blank" rel="noopener noreferrer">
                        <Logo bottom="0.3125rem" width="14rem" src={JMILogo} />
                      </a>
                    </ColumnContent>
                  </FooterColumn>
                  <FooterColumn>
                    <ColumnHeader>
                      <Typography variant="subtitle1" color="black">
                        {t('supported')}
                      </Typography>
                    </ColumnHeader>
                    <ColumnContent>
                      <a href="http://fitr.mk/" target="_blank" rel="noopener noreferrer">
                        <Logo bottom="0.3125rem" width="12.5rem" src={creativeEropean} />
                      </a>
                    </ColumnContent>
                  </FooterColumn>
                </Box>{' '}
                <Button
                  variant="contained"
                  color="primary"
                  asLink
                  href="mailto:support@techvenia.com"
                >
                  {t('contact_suport')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </FooterContainer>
      <SubFooter />
    </>
  );
};

Footer.propTypes = {};

export default Footer;

// eslint-disable-next-line no-lone-blocks
{
  /* <a target="_blank" rel="noopener noreferrer" href="https://suport@mubazar.com">
    {' '}
    Contact support
</a>; */
}
